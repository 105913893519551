* {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: rgb(0, 0, 0);
}

body::-webkit-scrollbar {
  display: none;
}

ul > li {
  list-style: none;
  font-weight: bold;
}

button {
  border: 2px solid white;
  padding: 0.7rem 3rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.3rem;
  background: #cb450c;
  transition: 0.5s;
}

button:hover {
  background: white;
  color: black;
  border: 2px solid black;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.App {
  background-image: url('./assets/effect.png');
  animation: static 3s steps(5, end) infinite;
  z-index: 999;
  background-size: 200px;
}
#root {
  user-select: none;
}

footer h3 {
  text-align: center;
  color: white;
  margin: 0;
  padding: 2% 0;
  letter-spacing: 0.1rem;
}

@keyframes static {
  0% {
    background-position: 0% 0%;
  }

  20% {
    background-position: 25% 15%;
  }

  40% {
    background-position: 50% 69%;
  }

  60% {
    background-position: 33% 25%;
  }

  80% {
    background-position: 72% 4%;
  }

  100% {
    background-position: 80% 91%;
  }
}

@media screen and (min-resolution: 100dpi) {
  button {
    font-size: 1rem;
  }
}
